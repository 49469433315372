import React from 'react';

import FlexHeroHomepage from './blocks/FlexHeroHomepage';
import FlexCtaSection from './blocks/FlexCtaSection';
import FlexTextColumn from './blocks/FlexTextColumn';
import FlexTextColumnAlt from './blocks/FlexTextColumnAlt';
import FlexDivider from './blocks/FlexDivider';
import FlexDividerLarge from './blocks/FlexDividerLarge';
import FlexUvp from './blocks/FlexUvp';
import FlexContentBanner from './blocks/FlexContentBanner';
import FlexContentBannerAlt from './blocks/FlexContentBannerAlt';
import FlexClients from './blocks/FlexClients';
import FlexFeatures from './blocks/FlexFeatures';
import FlexHeroNav from './blocks/FlexHeroNav';
import FlexHireFeatures from './blocks/FlexHireFeatures';
import FlexGetHiredFeatures from './blocks/FlexGetHiredFeatures';
import FlexHireCta from './blocks/FlexHireCta';
import FlexGetHiredCta from './blocks/FlexGetHiredCta';
import FlexHireBgBlock from './blocks/FlexHireBgBlock';
import FlexGetHiredBgBlock from './blocks/FlexGetHiredBgBlock';
import FlexHeroBanner from './blocks/FlexHeroBanner';
import FlexTwoColumnsText from './blocks/FlexTwoColumnsText';
import FlexFullWidthText from './blocks/FlexFullWidthText';
import FlexBios from './blocks/FlexBios';
import FlexTimeline from './blocks/FlexTimeline';

const FlexContentBlocks = ({ contentBlocks }) => {
  return contentBlocks.map((block: any, index: number) => {
    const { fieldGroupName } = block;

    switch (fieldGroupName) {
      case `Homepage_Cta`:
        return <FlexCtaSection key={index} block={block} index={index} />;

      case `Homepage_Text`:
        return <FlexTextColumn key={index} block={block} index={index} />;

      case `Homepage_Divider`:
        return <FlexDivider key={index} />;

      case `Homepage_UniqueValueProposition`:
        return <FlexUvp key={index} block={block} index={index} />;

      case `Homepage_Banner`:
        return <FlexContentBanner key={index} block={block} index={index} />;

      case `Homepage_Clients`:
        return <FlexClients key={index} block={block} />;

      case `Homepage_Features`:
        return <FlexFeatures key={index} block={block} index={index} />;

      case `Homepage_HeroWithAnimation`:
        return <FlexHeroHomepage key={index} block={block} index={index} />;

      case `Getstarted_HireGetHired`:
        return <FlexHeroNav key={index} block={block} index={index} />;

      case `Getstarted_Hire`:
        return <FlexHireFeatures key={index} block={block} />;

      case `Getstarted_GetStartedCta`:
        return <FlexHireCta key={index} block={block} index={index} />;

      case `Getstarted_TestimonialsWithForm`:
        return <FlexHireBgBlock key={index} block={block} index={index} />;

      case `Getstarted_Banner`:
        return <FlexContentBanner key={index} block={block} index={index} />;

      case `Getstarted_GetHired`:
        return <FlexGetHiredFeatures key={index} block={block} />;

      case `Getstarted_GetHiredCta`:
        return <FlexGetHiredCta key={index} block={block} />;

      case `Getstarted_Testimonials`:
        return <FlexGetHiredBgBlock key={index} block={block} />;

      case `Aboutus_HeroWithImage`:
        return <FlexHeroBanner key={index} block={block} index={index} />;

      case `Aboutus_TwoColumnsText`:
        return <FlexTwoColumnsText heading="h1" key={index} block={block} cssColumns={!block?.leftSide || !block?.rightSide} />;

      case `Aboutus_FullWidthText`:
        return <FlexFullWidthText key={index} block={block} index={index} />;

      case `Aboutus_Divider`:
        return <FlexDividerLarge key={index} />;

      case `Aboutus_Bios`:
        return <FlexBios key={index} block={block} index={index} />;

      case `Aboutus_Timeline`:
        return <FlexTimeline key={index} block={block} index={index} />;

      case `Aboutus_Banner`:
        return <FlexContentBannerAlt key={index} block={block} />;

      case `Aboutus_Text`:
        return <FlexTextColumnAlt key={index} block={block} index={index} />;

      case `Aboutus_Cta`:
        return <FlexCtaSection key={index} block={block} index={index} />;
      default:
        break;
    }
    return false;
  });
};
export default FlexContentBlocks;
