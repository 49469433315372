import React from 'react';
import { graphql } from 'gatsby';

import SEO from '../components/Seo';
import Layout from '../components/Layout';
import FlexContentBlocks from '../query/FlexContentBlocks';
import IStrapiAboutUsPage from '../interfaces/IStrapiAboutUsPage';

interface Props {
  data: {
    strapiAboutUsPage: IStrapiAboutUsPage;
  };
}

const AboutUsPage = ({ data }: Props) => {
  const { seo } = data.strapiAboutUsPage;

  const {
    title: seoTitle,
    description: seoDescription,
    image: seoImage,
    twitterImage: seoTwitterImage,
  } = seo;

  return (
    <Layout headerLocation="about">
      <SEO
        title={seoTitle}
        description={seoDescription}
        url="/about-us/"
        image={seoImage?.localFile?.publicURL ? seoImage?.localFile?.publicURL : undefined}
        twitterImage={
          seoTwitterImage?.localFile?.publicURL ? seoTwitterImage?.localFile?.publicURL : undefined
        }
      />
      <FlexContentBlocks contentBlocks={getBlocks(data.strapiAboutUsPage)} />
    </Layout>
  );
};

const formatImage = (image: any) => ({
  altText: image.alternativeText,
  mediaItemUrl: image.localFile.publicURL,
  imageFile: {
    childImageSharp: image.localFile.childImageSharp,
  },
});

const getBlocks = (data: IStrapiAboutUsPage) => {
  const {
    heroImage,
    heroImageMobile,
    heading,
    heroTextLeft,
    heroTextRight,
    heroTextCentral,
    giladImage,
    giladName,
    giladPosition,
    giladContent,
    mayaName,
    mayaPosition,
    mayaContent,
    mayaImage,
    matchHeading,
    matchSubheading,
    matchItems,
    matchResult,
    partnerImage,
    partnerImageMobile,
    partnerHeading,
    partnerText,
    partnerSubheading,
    ctaImage,
    ctaHeading,
    ctaSubheading,
    ctaFirstButtonText,
    ctaFirstButtonUrl,
    ctaSecondButtonText,
    ctaSecondButtonUrl,
  } = data;

  return [
    {
      fieldGroupName: 'Aboutus_HeroWithImage',
      imageMobile: formatImage(heroImageMobile),
      imageDesktop: formatImage(heroImage),
    },
    {
      fieldGroupName: 'Aboutus_TwoColumnsText',
      heading,
      rightSide: heroTextRight,
      leftSide: heroTextLeft,
    },
    { fieldGroupName: 'Aboutus_Divider', sectionId: null },
    {
      fieldGroupName: 'Aboutus_FullWidthText',
      text: heroTextCentral,
    },
    {
      fieldGroupName: 'Aboutus_Bios',
      bios: [
        {
          fieldGroupName: 'bios',
          image: formatImage(giladImage),
          fullName: giladName,
          description: giladPosition,
          text: giladContent,
        },
        {
          fieldGroupName: 'bios',
          image: formatImage(mayaImage),
          fullName: mayaName,
          description: mayaPosition,
          text: mayaContent,
        },
      ],
    },
    {
      fieldGroupName: 'Aboutus_Timeline',
      heading: matchHeading,
      description: matchSubheading,
      iconsWithDescription: matchItems.map(item => ({
        fieldGroupName: 'icons_with_description',
        icon: {
          sourceUrl: item.icon.url,
          altText: item.icon.alternativeText,
        },
        description: item.text,
      })),
      fullWidthText: matchResult,
    },
    {
      fieldGroupName: 'Aboutus_Banner',
      backgroundImageMobile: formatImage(partnerImageMobile),
      backgroundImageDesktop: formatImage(partnerImage),
      sectionId: null,
      heading: partnerHeading,
      subheading: '',
    },
    {
      fieldGroupName: 'Aboutus_Text',
      sectionId: null,
      reverseElements: true,
      heading: partnerSubheading,
      text: partnerText,
      button: {
        target: '',
        title: 'bestmatch@flatworld.co',
        url: 'mailto:bestmatch@flatworld.co',
      },
    },
    {
      fieldGroupName: 'Aboutus_Cta',
      sectionId: null,
      heading: ctaHeading,
      subheading: ctaSubheading,
      image: formatImage(ctaImage),
      darkButton: {
        target: '',
        title: ctaFirstButtonText,
        url: ctaFirstButtonUrl,
      },
      lightButton: {
        target: '',
        title: ctaSecondButtonText,
        url: ctaSecondButtonUrl,
      },
    },
  ];
};

export default AboutUsPage;

export const pageQuery = graphql`
  query {
    strapiAboutUsPage {
      heroImage {
        alternativeText
        localFile {
          childImageSharp {
            gatsbyImageData(
              layout: CONSTRAINED
              breakpoints: [750, 1200]
              width: 800
              quality: 70
              formats: [AUTO, WEBP]
              placeholder: NONE
            )
          }
        }
      }
      heroImageMobile {
        alternativeText
        localFile {
          childImageSharp {
            gatsbyImageData(
              layout: CONSTRAINED
              breakpoints: [750, 1200]
              width: 800
              quality: 70
              formats: [AUTO, WEBP]
              placeholder: NONE
            )
          }
        }
      }
      heading
      heroTextLeft
      heroTextRight
      heroTextCentral
      giladImage {
        alternativeText
        localFile {
          childImageSharp {
            gatsbyImageData(
              layout: CONSTRAINED
              breakpoints: [750, 1200]
              width: 800
              quality: 70
              formats: [AUTO, WEBP]
              placeholder: NONE
            )
          }
        }
      }
      giladName
      giladPosition
      giladContent
      mayaName
      mayaPosition
      mayaContent
      mayaImage {
        alternativeText
        localFile {
          childImageSharp {
            gatsbyImageData(
              layout: CONSTRAINED
              breakpoints: [750, 1200]
              width: 800
              quality: 70
              formats: [AUTO, WEBP]
              placeholder: NONE
            )
          }
        }
      }
      matchHeading
      matchSubheading
      matchItems {
        icon {
          alternativeText
          url
        }
        text
      }
      matchResult
      partnerImage {
        alternativeText
        localFile {
          childImageSharp {
            gatsbyImageData(
              layout: CONSTRAINED
              breakpoints: [750, 1200]
              width: 800
              quality: 70
              formats: [AUTO, WEBP]
              placeholder: NONE
            )
          }
        }
      }
      partnerImageMobile {
        alternativeText
        localFile {
          childImageSharp {
            gatsbyImageData(
              layout: CONSTRAINED
              breakpoints: [750, 1200]
              width: 800
              quality: 70
              formats: [AUTO, WEBP]
              placeholder: NONE
            )
          }
        }
      }
      partnerHeading
      partnerText
      partnerSubheading
      ctaImage {
        alternativeText
        localFile {
          childImageSharp {
            gatsbyImageData(
              layout: CONSTRAINED
              breakpoints: [750, 1200]
              width: 800
              quality: 70
              formats: [AUTO, WEBP]
              placeholder: NONE
            )
          }
        }
      }
      ctaHeading
      ctaSubheading
      ctaFirstButtonText
      ctaFirstButtonUrl
      ctaSecondButtonText
      ctaSecondButtonUrl
      seo {
        title
        description
        image {
          localFile {
            publicURL
          }
        }
        twitterImage {
          localFile {
            publicURL
          }
        }
      }
    }
  }
`;
